import React, { useState, useEffect } from 'react';
import './contactForm.css';
import InputMask from 'react-input-mask';
import person from './Images/person.svg';
import person_mob from './Images/person_mob.svg';
import cls_btn from './Images/close-button.svg';

const ContactForm = ({ onClose }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('+7');
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const handlePhoneChange = (e) => {
    const cleanedValue = e.target.value.replace(/\D/g, ''); // Оставляем только цифры
    setPhoneNumber(cleanedValue);
  };

  const handleSubmit = async () => {
    // Проверяем корректность номера телефона перед отправкой
    if (phoneNumber.length !== 11) {
      setPhoneNumberError(true);
      return;
    }

    try {
      // Отправляем данные на сервер
      const response = await fetch('https://schtern-mail.ru/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          firstName,
          lastName,
          phoneNumber
        })
      });

      if (response.ok) {
        console.log('Email sent successfully');
        setPhoneNumberError(false);
        onClose();
        // Здесь ты можешь добавить дополнительную обработку успешной отправки
      } else {
        console.error('Error sending email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <>
      {isDesktop > 480 ? (
        <div className="contact-form">
          <div className="img-contact_us">
            <img src={person} alt="person" />
          </div>

          <div className="contact-form-container">
            <p className="form-heading">
              Дайте нам знать, как с вами связаться, <br/>и наш менеджер ответит на ваши вопросы
            </p>
            <form>
              <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Имя"
              />

              <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Фамилия"
              />

              <InputMask
                  mask="+7 999-999-99-99"
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  placeholder="Номер телефона"
              />
              {phoneNumberError && (
                  <p className="error-message">Пожалуйста, укажите корректный номер телефона</p>
              )}

              <button type="button" onClick={handleSubmit}>
                Отправить
              </button>
            </form>
            <p>Нажимая, вы соглашаетесь с условиями политики конфиденциальности</p>
            <span>
              Бесплатный замер доступен только на территории г. Липецка
            </span>
          </div>
          <img src={cls_btn} alt="" onClick={onClose} className="contact-form__close"/>
        </div>
      ) : (
          <div className="contact-form">
          <div className="contact-form-container">
            <p className="form-heading">
              Дайте нам знать, как с вами связаться, <br />и наш менеджер ответит на ваши вопросы
            </p>
            <form>
              <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Имя"
              />

              <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Фамилия"
              />

              <InputMask
                  mask="+7 999-999-99-99"
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  placeholder="Номер телефона"
              />
              {phoneNumberError && (
                  <p className="error-message">Пожалуйста, укажите корректный номер телефона</p>
              )}

              <button type="button" onClick={handleSubmit}>
                Отправить
              </button>
              <p style={{fontSize: '2vw'}}>
                Нажимая, вы соглашаетесь с условиями политики конфиденциальности
              </p>

              <span style={{fontSize: '2vw'}}>
                Бесплатный замер доступен только на территории г. Липецка
              </span>
            </form>
          </div>
          <div className="img-contact_us">
            <img src={person_mob} alt="person"/>
          </div>
          <img src={cls_btn} alt="" onClick={onClose} className="contact-form__close" />
        </div>
      )}
    </>
  );
};
export default ContactForm;
